$(document).ready(function() {
  $('.header-all__burger').click(function() {
    $('.header-adaptive').toggleClass('menu-transform');
    $('body').toggleClass('overflow');
    $('.burger-one').toggleClass('active');
    $('.burger-two').toggleClass('active');
    $('.burger-three').toggleClass('active');
  });

  $('.choice-accept').click(function() {
    $('#verify').fadeOut(500);
  });
  $('.choice-decline').click(function() {
    window.history.back();
  });

  $('#pass-view').click(function() {
    $('#password').attr('type', 'text');
    $(this).css('display', 'none');
    $('#pass-noview').css('display', 'block');
  });
  $('#pass-noview').click(function() {
    $('#password').attr('type', 'password');
    $(this).css('display', 'none');
    $('#pass-view').css('display', 'block');
  });


  $(".openPopup").click(function() {
    $("#popup-bg").css("display", "block");
    $("body").css("overflow-y", "hidden");
  });
  $("#closePopup").click(function() {
    $("#popup-bg").css("display", "none");
    $("body").css("overflow-y", "visible");
  });


  $(".options").click(function() {
    $(".downloads-hide").toggleClass("downloads-show");
  });



  let tab1 = document.querySelector("#tab1");
  let tab2 = document.querySelector("#tab2");
  let tab3 = document.querySelector("#tab3");
  let tabs = document.querySelectorAll(".tab");
  let content = document.querySelectorAll(".content");


  tab1.addEventListener("click", () => {
    if (tab1.checked) {
      content[0].classList.add("active-content");
      content[1].classList.remove("active-content");
      content[2].classList.remove("active-content");
      tabs[0].classList.add("active-tab");
      tabs[1].classList.remove("active-tab");
      tabs[2].classList.remove("active-tab");
    }
  });

  tab2.addEventListener("click", () => {
    if (tab2.checked) {
      content[0].classList.remove("active-content");
      content[1].classList.add("active-content");
      content[2].classList.remove("active-content");
      tabs[1].classList.add("active-tab");
      tabs[0].classList.remove("active-tab");
      tabs[2].classList.remove("active-tab");
    }
  });

  tab3.addEventListener("click", () => {
    if (tab3.checked) {
      content[0].classList.remove("active-content");
      content[1].classList.remove("active-content");
      content[2].classList.add("active-content");
      tabs[2].classList.add("active-tab");
      tabs[0].classList.remove("active-tab");
      tabs[1].classList.remove("active-tab");
    }
  });



  let modelTab1 = document.querySelector("#modelTab1");
  let modelTab2 = document.querySelector("#modelTab2");
  let modelTabs = document.querySelectorAll(".modelTab");
  let modelContent = document.querySelectorAll(".modelContent");


  modelTab1.addEventListener("click", () => {
    if (modelTab1.checked) {
      modelContent[0].classList.add("modelContent-active");
      modelContent[1].classList.remove("modelContent-active");
      modelTabs[0].classList.add("modelTab-active");
      modelTabs[1].classList.remove("modelTab-active");
    }
  });

  modelTab2.addEventListener("click", () => {
    if (modelTab2.checked) {
      modelContent[0].classList.remove("modelContent-active");
      modelContent[1].classList.add("modelContent-active");
      modelTabs[0].classList.remove("modelTab-active");
      modelTabs[1].classList.add("modelTab-active");
    }
  });


  lightGallery(document.getElementById('static-thumbnails'), {
    animateThumb: false,
    zoomFromOrigin: false,
    allowMediaOverlap: true,
    toggleThumb: true,
    showCloseIcon: true,
    download: true,
    rotate: true,
    mousewheel: true,
    download: true,
    controls: true,
    isMobile: true
  });

  lightGallery(document.getElementById('static-thumbnails-two'), {
    animateThumb: false,
    zoomFromOrigin: false,
    allowMediaOverlap: true,
    toggleThumb: true,
    showCloseIcon: true,
    download: true,
    rotate: true,
    mousewheel: false,
    download: true,
    controls: true,
    isMobile: true
  });

});